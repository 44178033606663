<script lang="ts" setup>
withDefaults(defineProps<{ image?: string; imageMobile?: string; bgColor?: string; fetchpriority?: string }>(), {
	image: "",
	imageMobile: ""
});

const prepareImgUrl = useImage();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();
</script>
<template>
	<ABanner
		:img-attrs="{
			src: `${baseImageUrl}${image}`,
			format: 'avif',
			height: 310,
			alt: 'banner',
			fetchpriority,
			preload: !!fetchpriority
		}"
		:sources="[
			{
				srcset: prepareImgUrl(`${baseImageUrl}${imageMobile}`, {
					format: 'avif',
					width: 656,
					height: 340
				}),
				width: 328,
				height: 170,
				media: '(max-width: 767px)'
			}
		]"
		:background="bgColor || 'var(--goma)'"
		class="banner"
	>
		<div class="banner-content">
			<slot name="badge" />
			<slot />
		</div>
	</ABanner>
</template>
<style lang="scss" scoped>
.banner {
	flex-shrink: 0;

	&-content {
		padding: gutter(6.5) gutter(5);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		color: var(--cannes);
		gap: gutter(1);

		@include media-breakpoint-down(md) {
			padding: gutter(2) gutter(1);
			justify-content: flex-end;
			align-items: center;
			width: 100%;
		}

		&:deep(button) {
			flex-shrink: 0;
			width: 240px;
			margin-top: gutter(4);

			@include media-breakpoint-down(md) {
				--a-button-size-lg-height: 40px;

				margin-top: gutter(2);
			}
		}

		&:deep(.text-coins) {
			color: var(--coimbatore);
		}

		&:deep(.text-entries) {
			color: var(--cixi);
		}

		&:deep(.title) {
			line-height: 45px;

			@include media-breakpoint-down(md) {
				font-size: 20px;
				line-height: 21px;
			}

			.text-coins {
				position: relative;
				padding-left: gutter(5.5);

				&::before {
					content: "";
					position: absolute;
					background: url("/nuxt-img/cards/coins.png") center/cover no-repeat;
					width: 40px;
					height: 40px;
					left: 0;
					top: 0;
				}

				@include media-breakpoint-down(md) {
					padding-left: gutter(3.5);

					&::before {
						width: 24px;
						height: 24px;
					}
				}
			}
		}

		&:deep(.subtitle) {
			display: flex;
			align-items: center;

			@include media-breakpoint-down(md) {
				font-size: 16px;
				justify-content: center;
			}

			.text-coins,
			.text-entries {
				font-weight: 900;
				font-size: 32px;
				position: relative;
				padding-left: gutter(4);
				padding-right: gutter(1.5);

				@include media-breakpoint-down(md) {
					font-size: 16px;
					padding-left: gutter(3);
				}

				&::before {
					content: "";
					position: absolute;
					left: 0;
					bottom: 6px;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					width: 28px;
					height: 28px;

					@include media-breakpoint-down(md) {
						width: 20px;
						height: 20px;
						bottom: 0;
					}
				}
			}

			.text-coins::before {
				background-image: url("/nuxt-img/cards/coins.png");
			}

			.text-entries::before {
				background-image: url("/nuxt-img/cards/entries.png");
			}
		}
	}
}
</style>
